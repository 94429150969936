import {
    POINT_SETTINGS_SUCCESS,
    SAVING_ADMIN_POINTS_SUCCESS
} from '../constants/index'

export default function pointSettings(state = {}, action) {
    switch(action.type) {

        case POINT_SETTINGS_SUCCESS:
            return {
                ...state,
                data: action.payload
            }
        case SAVING_ADMIN_POINTS_SUCCESS:
            return {
                ...state,
                data: action.payload
            }
        default:
            return {
              ...state
            };
    }
};
  